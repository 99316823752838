import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { campaignService } from '@/services/campaign.js'

export const useCampaignStore = defineStore('campaign', () => {
  const campaign = ref(undefined)
  const campaigns = ref([])
  const showParamsConfig = ref(false)

  const hasQueryParams = computed(() => {
    return Object.keys(campaign.value?.filters?.query?.params).length > 0
  })

  const getNetwork = computed(() => {
    if (campaign.value.filters?.facebook) {
      return 'facebook'
    }

    if (campaign.value.filters?.google) {
      return 'google'
    }

    if (campaign.value.filters?.tiktok) {
      return 'tiktok'
    }

    if (campaign.value.filters?.kwai) {
      return 'kwai'
    }

    return null
  })

  function reset() {
    campaign.value = undefined
  }

  async function findById(id) {
    const { data } = await campaignService.findById(id)
    campaign.value = data
  }

  async function create(form) {
    const { data } = await campaignService.create(form)
    campaign.value = data

    return data
  }

  async function update(id, form) {
    const { data } = await campaignService.update(id, form)
    campaign.value = data

    return campaign
  }

  async function fetchCampaigns() {
    const { data } = await campaignService.findAll()
    setCampaigns(data.data)
  }

  function setCampaigns(form) {
    campaigns.value = form
  }

  function toggleParamsConfig() {
    if (hasQueryParams.value) {
      showParamsConfig.value = true
    }
  }

  return {
    campaign,
    campaigns,
    setCampaigns,
    fetchCampaigns,
    reset,
    findById,
    update,
    create,
    toggleParamsConfig,
    showParamsConfig,
    hasQueryParams,
    getNetwork
  }
})
